import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import Qrcode from "../../assets/images/qr-code.png";
import { ethers } from "ethers";
import { useUser, useAuth } from "@clerk/clerk-react";
import axios from "axios";
import { Dropdown } from "react-bootstrap";

// const ARBITRUM_SEPOLIA_RPC = "https://sepolia-rollup.arbitrum.io/rpc"; // Official RPC for Arbitrum Sepolia
// const MOCK_OPTIMA_USD_ADDRESS = "0x11b1f7ee41bfe9f43251c382e851578fa5010c97"; // Mock Optimus USD Address for Arbitrum Sepolia

// Network configurations
const NETWORKS = {
  REDBELLY: {
    name: "RedBelly Testnet",
    rpc: "https://governors.testnet.redbelly.network",
    optimaUsdAddress: "0xeeA69cb740081ddf2683Db6aD6915B69C7854c40",
    chainId: 153, // Add the actual chain ID
    explorer: "https://redbelly.testnet.routescan.io",
  },
  ARBITRUM: {
    name: "Arbitrum Sepolia",
    rpc: "https://sepolia-rollup.arbitrum.io/rpc",
    optimaUsdAddress: "0x11b1f7ee41bfe9f43251c382e851578fa5010c97",
    chainId: 421614,
    explorer: "https://sepolia.arbiscan.io",
  },
};

const Deposit = () => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const [selectedCrypto, setSelectedCrypto] = useState("USDC");
  const [selectedNetwork, setSelectedNetwork] = useState(NETWORKS.REDBELLY);
  const [walletAddress, setWalletAddress] = useState("");
  const [baskets, setBaskets] = useState([]);
  const [selectedBasketId, setSelectedBasketId] = useState(null);
  const [basketDetails, setBasketDetails] = useState(null);
  const [depositAmount, setDepositAmount] = useState("");
  const [optimaWalletAddress, setOptimaWalletAddress] = useState(null);
  const [depositFormAmount, setDepositFormAmount] = useState("");
  const [userBalance, setUserBalance] = useState("0");
  const [basketAddress, setBasketAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const [basketBalance, setBasketBalance] = useState("0");
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const [showWalletCreatedModal, setShowWalletCreatedModal] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(true);
  const [showKycModal, setShowKycModal] = useState(false);
  const [kycMessage, setKycMessage] = useState("");
  const [kycRedirectUrl, setKycRedirectUrl] = useState("");

  React.useEffect(() => {
    if (user?.primaryWeb3Wallet) {
      setWalletAddress(user.primaryWeb3Wallet.web3Wallet);
    }
  }, [user]);

  {
    /* Fetching baskets */
  }
  useEffect(() => {
    const fetchBaskets = async () => {
      try {
        const token = await getToken({ template: "optima" });

        const response = await axios.get(
          "https://optima-api-r2o2v.ondigitalocean.app/baskets",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Baskets response:", response.data);
        setBaskets(response.data);
      } catch (error) {
        console.error("Error fetching baskets:", error);
      }
    };
    fetchBaskets();
  }, [getToken]);

  {
    /* Fetching optima wallet address */
  }
  useEffect(() => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const fetchOptimaWallet = async (retryCount = 0) => {
      try {
        const token = await getToken({ template: "optima" });

        // Add delay before making the request
        await delay(1000); // 1 second delay

        const response = await axios.get(
          "https://optima-api-r2o2v.ondigitalocean.app/users/account",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(
          "Complete API response:",
          JSON.stringify(response.data, null, 2)
        );

        if (response.data?.data?.wallet === null) {
          setIsCreatingWallet(true);

          // Add delay before creating wallet
          await delay(1000); // 1 second delay

          const userData = {
            name: user.firstName || "",
            surname: user.lastName || "",
            email: user.primaryEmailAddress?.emailAddress || "",
            country: user.publicMetadata?.country || "Unknown",
          };

          const createResponse = await axios.post(
            "https://optima-api-r2o2v.ondigitalocean.app/users/create",
            userData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setOptimaWalletAddress(
            createResponse.data?.data?.wallet?.wallet_address
          );
          setShowWalletCreatedModal(true);
          setIsCreatingWallet(false);
        } else {
          const walletAddress = response.data?.data?.wallet?.wallet_address;
          if (!walletAddress) {
            throw new Error("Could not find wallet address in API response");
          }
          setOptimaWalletAddress(walletAddress);
        }
      } catch (error) {
        console.error("Detailed error in fetchOptimaWallet:", error);

        // Handle rate limit error with retries
        if (error.response?.status === 429 && retryCount < 3) {
          console.log(
            `Rate limited, retrying in ${(retryCount + 1) * 2} seconds...`
          );
          await delay((retryCount + 1) * 2000); // Exponential backoff
          return fetchOptimaWallet(retryCount + 1);
        }

        alert(`Failed to fetch/create wallet: ${error.message}`);
        setIsCreatingWallet(false);
      }
    };

    fetchOptimaWallet();
  }, [getToken, user]);

  // Update the balance fetching useEffect
  useEffect(() => {
    const fetchBalance = async () => {
      setIsBalanceLoading(true); // Set loading to true at the start of fetch
      try {
        if (!walletAddress || !selectedNetwork) {
          console.log("Waiting for wallet address or network...");
          return;
        }

        const provider = new ethers.providers.JsonRpcProvider(
          selectedNetwork.rpc
        );

        if (selectedCrypto === "BASKET" && basketAddress) {
          // Fetch basket token balance
          const basketContract = new ethers.Contract(
            basketAddress,
            [
              "function balanceOf(address account) external view returns (uint256)",
            ],
            provider
          );

          const balance = await basketContract.balanceOf(walletAddress);
          const formattedBalance = ethers.utils.formatUnits(balance, 18);
          setBasketBalance(formattedBalance);
        } else {
          // Fetch USDC balance
          const mockOptimaUSDContract = new ethers.Contract(
            selectedNetwork.optimaUsdAddress,
            [
              "function balanceOf(address account) external view returns (uint256)",
            ],
            provider
          );

          const balance = await mockOptimaUSDContract.balanceOf(walletAddress);
          const formattedBalance = ethers.utils.formatUnits(balance, 18);
          setUserBalance(formattedBalance);
        }
      } catch (error) {
        console.error("Error fetching balance:", error);
      } finally {
        setIsBalanceLoading(false);
      }
    };

    fetchBalance();
  }, [walletAddress, selectedNetwork, selectedCrypto, basketAddress]);

  // Add effect to fetch basket address
  useEffect(() => {
    const fetchBasketDetails = async () => {
      if (!selectedBasketId) return;

      try {
        const token = await getToken({ template: "optima" });
        console.log("Fetching basket with ID:", selectedBasketId);

        const response = await axios.get(
          `https://optima-api-r2o2v.ondigitalocean.app/baskets/${selectedBasketId}/overview`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Basket details response:", response.data);
        // Get the address from the nested data structure
        const rawBasketAddress = response.data.data.addresses["153"];

        if (!rawBasketAddress) {
          throw new Error("Basket address not found in response");
        }

        // Use ethers to format the address correctly
        const formattedBasketAddress =
          ethers.utils.getAddress(rawBasketAddress);
        console.log("Formatted basket address:", formattedBasketAddress);

        setBasketAddress(formattedBasketAddress);
        setBasketDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching basket details:", error);
        alert(`Failed to fetch basket details: ${error.message}`);
      }
    };

    fetchBasketDetails();
  }, [selectedBasketId, getToken]);

  // Original function for USDC deposits
  const handleExchangeForCredits = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTxHash(null);

    try {
      if (!optimaWalletAddress) {
        alert(
          "Optima wallet address not available. Please wait or refresh the page."
        );
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Check and switch network if needed
      const network = await provider.getNetwork();
      if (network.chainId !== selectedNetwork.chainId) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              { chainId: ethers.utils.hexValue(selectedNetwork.chainId) },
            ],
          });
        } catch (switchError) {
          throw new Error(`Please switch to ${selectedNetwork.name} network`);
        }
      }

      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const userAddress = await signer.getAddress();

      // Create contract interface
      const iface = new ethers.utils.Interface([
        "function exchangeForCredits(uint256 amount, address smartWallet) external",
      ]);

      // Encode function data
      const amountStr = depositFormAmount + "000000000000000000";
      const data = iface.encodeFunctionData("exchangeForCredits", [
        amountStr,
        optimaWalletAddress,
      ]);

      // Get nonce and gas price
      const nonce = await provider.getTransactionCount(userAddress);
      const gasPrice = await provider.getGasPrice();

      // Create transaction object
      const txObject = {
        from: userAddress,
        to: selectedNetwork.optimaUsdAddress,
        data: data,
        gasLimit: ethers.utils.hexlify(150000),
        gasPrice: gasPrice,
        nonce: nonce,
      };

      console.log("Transaction object:", txObject);

      // Send transaction
      const tx = await signer.sendTransaction(txObject);
      console.log("Transaction sent:", tx.hash);
      setTxHash(tx.hash);

      const receipt = await tx.wait();
      console.log("Transaction receipt:", receipt);

      if (receipt.status !== 1) {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      console.error("Transaction error:", error);
      alert(`Transaction failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Add new function for basket deposits
  const handleBasketDeposit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTxHash(null);

    try {
      if (!basketAddress) {
        throw new Error("Basket address not available");
      }

      console.log("Using basket address:", basketAddress);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      // Log the connected wallet address
      const userAddress = await signer.getAddress();
      console.log("Connected wallet:", userAddress);

      // Create basket contract interface
      const basketContract = new ethers.Contract(
        basketAddress,
        [
          "function exchangeForCredits(uint256 amount, address smartWallet) external",
        ],
        signer
      );

      // Convert deposit amount to wei (18 decimals)
      const depositAmountWei = ethers.utils.parseUnits(depositFormAmount, 18);
      console.log("Deposit amount in wei:", depositAmountWei.toString());

      // Execute exchangeForCredits
      console.log("Executing exchangeForCredits...");
      const tx = await basketContract.exchangeForCredits(
        depositAmountWei,
        optimaWalletAddress,
        {
          gasLimit: 300000,
          gasPrice: await provider.getGasPrice(),
        }
      );
      console.log("Transaction hash:", tx.hash);
      setTxHash(tx.hash);

      const receipt = await tx.wait();
      console.log("Transaction receipt:", receipt);

      if (receipt.status !== 1) {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      console.error("Transaction error:", error);
      alert(`Transaction failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update the handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check KYC status first
      const token = await getToken({ template: "optima" });
      const response = await fetch(
        `https://optima-api-r2o2v.ondigitalocean.app/chains/153/kyc/${user.primaryWeb3Wallet.web3Wallet}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const kycData = await response.json();

      if (!kycData.data.isVerified) {
        setKycMessage(kycData.data.message);
        setKycRedirectUrl(kycData.data.redirectUrl);
        setShowKycModal(true);
        return;
      }

      // If KYC is verified, proceed with deposit
      if (selectedCrypto === "BASKET") {
        handleBasketDeposit(e);
      } else {
        handleExchangeForCredits(e);
      }
    } catch (error) {
      console.error("Error checking KYC status:", error);
      alert("Failed to verify KYC status");
    }
  };

  return (
    <div className="container-fluid p-4">
      <form onSubmit={handleSubmit}>
        <div className="row g-4">
          {/* Token Type Selection */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">
              Select Token Type
            </label>
            <div className="input-group">
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary">
                  {selectedCrypto}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSelectedCrypto("USDC")}>
                    USDC
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedCrypto("BASKET")}>
                    BASKET
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {/* Basket Selection (only shown when BASKET is selected) */}
          {selectedCrypto === "BASKET" && (
            <div className="col-12">
              <label className="form-label text-muted mb-2">
                Select Basket
              </label>
              <select
                className="form-select"
                value={selectedBasketId || ""}
                onChange={(e) => setSelectedBasketId(e.target.value)}
                required
              >
                <option value="">Choose a basket...</option>
                <option value="1aec9f6c-1e06-4c18-b0f5-0d43d4fbccec">
                  Basket #OP10
                </option>
              </select>
            </div>
          )}

          {/* Amount Input with Balance Display */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">Amount</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter amount"
              value={depositFormAmount}
              onChange={(e) => setDepositFormAmount(e.target.value)}
              required
              min="0"
              step="any"
              max={selectedCrypto === "BASKET" ? basketBalance : userBalance}
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <small className="text-muted">
                Balance:{" "}
                {isBalanceLoading
                  ? "Loading..."
                  : selectedCrypto === "BASKET"
                  ? `${parseFloat(basketBalance).toFixed(2)} ${
                      basketDetails?.symbol || "BASKET"
                    }`
                  : `${parseFloat(userBalance).toFixed(2)} opUSDC`}
              </small>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                onClick={() =>
                  setDepositFormAmount(
                    selectedCrypto === "BASKET" ? basketBalance : userBalance
                  )
                }
                disabled={isBalanceLoading}
              >
                Max Deposit
              </button>
            </div>
          </div>

          {/* Network Selection */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">Network</label>
            <select
              className="form-select"
              value={selectedNetwork.name}
              onChange={(e) =>
                setSelectedNetwork(
                  Object.values(NETWORKS).find((n) => n.name === e.target.value)
                )
              }
            >
              {Object.values(NETWORKS).map((network) => (
                <option key={network.name} value={network.name}>
                  {network.name}
                </option>
              ))}
            </select>
          </div>

          {/* Submit Button */}
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-primary w-100 py-3 text-uppercase fw-bold"
              disabled={
                isLoading ||
                !depositFormAmount ||
                parseFloat(depositFormAmount) <= 0
              }
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Processing...
                </>
              ) : (
                "Confirm Deposit"
              )}
            </button>
          </div>
        </div>
      </form>

      {/* Loading Modal */}
      {isLoading && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="spinner-border text-primary mb-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <h5>Processing Transaction...</h5>
                {txHash && (
                  <div className="mt-3">
                    <small className="text-muted">
                      Transaction Hash:{" "}
                      <a
                        href={`${selectedNetwork.explorer}/tx/${txHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View on Explorer
                      </a>
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {!isLoading && txHash && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="text-success mb-3">
                  <i
                    className="bi bi-check-circle"
                    style={{ fontSize: "3rem" }}
                  ></i>
                </div>
                <h5>Transaction Successful!</h5>
                <div className="mt-3">
                  <a
                    href={`${selectedNetwork.explorer}/tx/${txHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary"
                  >
                    View on Explorer
                  </a>
                  <button
                    className="btn btn-secondary ms-2"
                    onClick={() => setTxHash(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Wallet Creation Modal */}
      {isCreatingWallet && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="spinner-border text-primary mb-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <h5>Creating Optima Wallet...</h5>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Wallet Created Success Modal */}
      {showWalletCreatedModal && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="text-success mb-3">
                  <i
                    className="bi bi-check-circle"
                    style={{ fontSize: "3rem" }}
                  ></i>
                </div>
                <h5>Successfully Created Optima Wallet!</h5>
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => setShowWalletCreatedModal(false)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* KYC Verification Modal */}
      {showKycModal && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">KYC Verification Required</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowKycModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>{kycMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowKycModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => (window.location.href = kycRedirectUrl)}
                >
                  Verify Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Deposit;

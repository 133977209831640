import React, { useState } from "react";
import img1 from "../../../assets/images/landingpage/img1.jpg";
import img7 from "../../../assets/images/landingpage/img7.jpg";
import img5 from "../../../assets/images/landingpage/img5.jpg";
import optima from "../../../assets/images/optimalogo2.jpg";

const BlogSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const blogPosts = [
    {
      image: optima,
      date: "Jan 16, 2025",
      author: "Delia Sabau",
      title:
        "Optima Financial: Making Digital Asset Investing Simple, Compliant and DeFi Connected",
      content: "The Golden Standard For Tokenized Investment Strategies",
      readTime: "3 min read",
      link: "https://medium.com/@heartfull_magenta_cat_850/optima-financial-making-digital-asset-investing-simple-compliant-and-defi-connected-f1ac30cafc69",
    },
  ];

  const Modal = ({ post, onClose }) => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "12px",
          width: "90%",
          maxWidth: "800px",
          maxHeight: "90vh",
          overflow: "auto",
          position: "relative",
          padding: "2rem",
        }}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
            border: "none",
            background: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
            color: "#4a5568",
          }}
        >
          ×
        </button>

        {/* Article content */}
        <div>
          <img
            src={post.image}
            alt={post.title}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "cover",
              borderRadius: "8px",
              marginBottom: "1.5rem",
            }}
          />

          <div style={{ marginBottom: "1rem" }}>
            <span style={{ color: "#718096", marginRight: "1rem" }}>
              <i className="fa fa-clock-o me-2"></i>
              {post.date}
            </span>
            <span style={{ color: "#718096" }}>
              <i className="fa fa-user me-2"></i>
              {post.author}
            </span>
          </div>

          <h2
            style={{
              color: "#2d3748",
              marginBottom: "1.5rem",
              fontSize: "2rem",
            }}
          >
            {post.title}
          </h2>

          <div
            dangerouslySetInnerHTML={{ __html: post.fullContent }}
            style={{
              color: "#4a5568",
              lineHeight: "1.8",
              "& h2": {
                color: "#2d3748",
                marginTop: "2rem",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              },
              "& h3": {
                color: "#2d3748",
                marginTop: "1.5rem",
                marginBottom: "1rem",
                fontSize: "1.25rem",
              },
              "& p": {
                marginBottom: "1rem",
              },
              "& blockquote": {
                borderLeft: "4px solid #4299e1",
                paddingLeft: "1rem",
                margin: "2rem 0",
                fontStyle: "italic",
                color: "#2d3748",
              },
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <section
      id="blog"
      className="blog-section section py-5"
      style={{
        backgroundColor: "#f8fafc",
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: "1200px", margin: "0 auto", marginBottom: "200px" }}
      >
        {/* Back button */}
        <div className="row mb-5">
          <div className="col-12 d-flex justify-content-end">
            <a
              href="/"
              className="btn btn-primary px-4"
              style={{
                backgroundColor: "#4a5568",
                border: "none",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <i className="fa fa-arrow-left me-2"></i>Back to Home
            </a>
          </div>
        </div>

        {/* Header Section */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <div className="section-title">
              <h2
                className="wow fadeInUp display-4 fw-bold mb-4"
                data-wow-delay=".4s"
                style={{
                  color: "#2d3748",
                  fontSize: "2.5rem",
                  marginBottom: "1rem",
                }}
              >
                Latest Insights and Updates
              </h2>
              <p
                className="wow fadeInUp lead"
                data-wow-delay=".6s"
                style={{
                  color: "#4a5568",
                  maxWidth: "800px",
                  margin: "0 auto",
                  fontSize: "1.1rem",
                  lineHeight: "1.6",
                }}
              >
                Explore our Blog for exclusive articles on the latest investment
                strategies, in-depth research, expert advices, and weekly market
                updates
              </p>
            </div>
          </div>
        </div>

        {/* Blog Cards */}
        <div className="row g-4 justify-content-center">
          {blogPosts.map((post, index) => (
            <div
              key={index}
              className="col-lg-8 col-md-10 col-12 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div
                className="card h-100"
                style={{
                  border: "none",
                  borderRadius: "12px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  backgroundColor: "white",
                  overflow: "hidden",
                }}
                onClick={() => window.open(post.link, "_blank")}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow =
                    "0 6px 12px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 6px rgba(0, 0, 0, 0.1)";
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={post.image}
                    alt={post.title}
                    style={{
                      height: "400px",
                      width: "auto",
                      objectFit: "contain",
                      transition: "transform .3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  />
                </div>
                <div className="card-body d-flex flex-column p-4">
                  <div className="mb-3">
                    <span className="me-3" style={{ color: "#718096" }}>
                      <i className="fa fa-clock-o me-2"></i>
                      {post.date} · {post.readTime}
                    </span>
                    <span style={{ color: "#718096" }}>
                      <i className="fa fa-user me-2"></i>
                      {post.author}
                    </span>
                  </div>
                  <h4 className="mb-3">
                    <a
                      href={post.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#2d3748",
                        textDecoration: "none",
                        fontSize: "1.25rem",
                        fontWeight: "600",
                      }}
                    >
                      {post.title}
                    </a>
                  </h4>
                  <p style={{ color: "#4a5568", flex: "1" }}>{post.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showModal && selectedPost && (
        <Modal
          post={selectedPost}
          onClose={() => {
            setShowModal(false);
            setSelectedPost(null);
          }}
        />
      )}
    </section>
  );
};

export default BlogSection;
